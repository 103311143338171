import indexLess from "./css/index.less";
import {
  Table,
  Button,
  Form,
  Pagination,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import type { FormInstance } from "antd";
import type { ColumnsType } from "antd/es/table";
import Dgform from "../../components/dg-form";
import React, { useState, useEffect, memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { NavigateFunction } from "react-router-dom";
import { itmeType } from "../../types";
import ajax from "../../assets/js/ajax";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

const { Option } = Select;
const { TextArea } = Input;
let navigate: NavigateFunction | null = null;
interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  align: string;
}

export default memo(() => {
  navigate = useNavigate();
  let [refForm] = Form.useForm();
  let [invoiceVisible, setInvoiceVisible] = useState(false);
  const columns: ColumnsType<DataType> = [
    {
      title: "提交时间",
      dataIndex: "gmtCreate",
      key: "gmtCreate",
      align: "center",
    },

    {
      title: "发票抬头",
      dataIndex: "invoiceHead",
      key: "invoiceHead",
      align: "center",
    },
    {
      title: "金额（元）",
      dataIndex: "amountStr",
      key: "amountStr",
      align: "center",
    },

    {
      title: "状态",
      dataIndex: "statusDesc",
      key: "statusDesc",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "address",
      align: "center",
      width: "150px",
      render: (text, record: any) => {
        let op1 = (
          <span
            className="aui-link aui-padded-l-6"
            onClick={() => {
              navigate!("/admin/invoice/detail", { state: { id: record.id } });
            }}
          >
            查看
          </span>
        );
        let op2 = (
          <span
            className="aui-link aui-padded-l-6"
            onClick={() => {
              refForm.setFieldsValue(record);
              setInvoiceVisible(true);
            }}
          >
            重新提交
          </span>
        );
        let oper = [];
        if (record.status == 2) {
          oper.push(op2);
        }

        oper.push(op1);

        return oper;
      },
    },
  ];

  // let refInput = React.createRef<HTMLInputElement>();
  let [statusAll, setStatusAll] = useState<any>([]);
  let [totalItems, setTotalItems] = useState<number>(0);
  let [allInvoiceType, setAllInvoiceType] = useState<any[]>([]);
  const formRef = useRef<{
    getFormRef: () => FormInstance<any>;
    getValues: () => any;
  }>();

  let [formItems, setFormItems] = useState<itmeType[]>([
    { label: "订单编号", type: "input", key: "tradeNo", des: "请输入订单编号" },
    {
      label: "提交结束时间",
      type: "date",
      model: [],
      key: "gmtCreateBegin,gmtCreateEnd",
      des: "请选择提交结束时间",
    },
    {
      label: "发票抬头",
      type: "input",
      key: "invoiceHead",
      des: "请输入发票抬头",
    },
    {
      label: "状态",
      type: "select",
      options: statusAll,
      key: "status",
      des: "请选择状态",
    },
  ]);
  let [data, setData] = useState<any>([]);

  // 地址： http://jinfu.baohan.com:8080/doc.html#/default/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0%E5%89%8D%E5%8F%B0-%E5%85%85%E5%80%BC%E8%AE%A2%E5%8D%95/pageUsingPOST_5
  // 接口： /openPlatform/order/page
  interface orderPage {
    pageNo: number | null; // 页数
    pageSize: number | null; // 条数
    gmtCreateBegin?: string; // 提交开始时间
    gmtCreateEnd?: string; // 提交结束时间
    sortDirection?: string; // 排序方向
    sortField?: string; // 排序字段
    status?: number | null; // 状态
    tradeNo?: string; // 订单编号
  }

  let [formData, setFormData] = useState<orderPage>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    gmtCreateBegin: "", // 提交开始时间
    gmtCreateEnd: "", // 提交结束时间
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
    status: null, // 状态
    tradeNo: "", // 订单编号
  });

  /**
   * @author 伟健 / 张
   * @name  获取订单状态
   * @param
   * @return
   */
  let getStatus = () => {
    ajax.post({
      url: "/openPlatform/common/allInvoiceState",
      data: {},
      self: this,
      success: (res) => {
        setStatusAll((data: any) => {
          res.map((el: any, index: Number) => {
            data.push({ ...el, key: index });
          });
          return data;
        });
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 搜索
   * @param
   * @return
   */
  let handelSeach = (values: any) => {
    setFormData({
      pageNo: 1,
      pageSize: 10,
      ...values,
    });
  };

  /**
   * @author 伟健 / 张
   * @name 列表
   * @param
   * @return
   */
  let getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/invoice/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 分页
   * @param
   * @return
   */

  let handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
      ...formRef.current?.getValues(),
    });
  };
  /**
   * @author 伟健 / 张
   * @name 提交发票申请
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/invoice/apply",
      data: values,
      self: this,
      success: (res) => {
        if (res) {
          setInvoiceVisible(false);
          message.success("提交成功");
          getList();
        }
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 发票类型
   * @param
   * @return
   */
  let getInvoiceType = () => {
    ajax.post({
      url: "/openPlatform/common/allInvoiceType",
      data: {},
      self: this,
      success: (res) => {
        setAllInvoiceType((data: any) => {
          data = [];
          res.map((el: any) => {
            data.push({ ...el });
          });
          return data;
        });
      },
      fail: () => {},
    });
  };
  useEffect(() => {
    getStatus();
    getInvoiceType();
  }, []);

  useEffect(() => {
    if (hasPermission("openPlatform:invoice:page")) {
    getList();
    }
  }, [formData]);

  // useEffect(() => {
  // 	// console.log("formItems",formItems);
  // 	// setFormItems(JSON.parse(JSON.stringify(formItems)));
  // }, [statusAll]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:invoice:page") ? (
        <div className="">
          <div className="aui-padded-b-12">
            <span className="aui-font-size-16 aui-192530 aui-padded-r-4">
              我的发票
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap} `}>
            {/* <input type="text" ref={refInput} /> */}

            <div className={` ${indexLess.bor_bttom}  aui-padded-26`}>
              <Dgform ref={formRef} items={formItems} handelSeach={handelSeach}>
                <Form.Item className="aui-row-right">
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
              </Dgform>
            </div>
            <div className="aui-padded-26">
              <Table
                rowSelection={{ type: "checkbox" }}
                columns={columns}
                pagination={false}
                dataSource={data}
              />
              <div className="aui-text-center aui-padded-t-24">
                <Pagination
                  onChange={handelPageChange}
                  total={totalItems}
                  showTotal={(total) => `总条数 ${total}`}
                ></Pagination>
              </div>
            </div>
          </div>

          <Modal
            title="申请发票"
            forceRender={true}
            maskClosable={true}
            onCancel={() => setInvoiceVisible(false)}
            open={invoiceVisible}
            footer={false}
            centered
            width={700}
          >
            <Form
              preserve={false}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              form={refForm}
              onFinish={handlerSubmint}
            >
              <Form.Item
                label=""
                className="aui-hide"
                hidden={false}
                name="tradeNo"
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="" className="aui-hide" hidden={false} name="id">
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                label=""
                className="aui-hide"
                hidden={false}
                name="version"
              >
                <Input placeholder="" />
              </Form.Item>

              <Form.Item
                label="发票抬头"
                name="invoiceHead"
                rules={[{ required: true, message: "请输入发票抬头" }]}
              >
                <Input placeholder="请输入发票抬头" />
              </Form.Item>
              <Form.Item
                label="发票类型"
                name="invoiceType"
                rules={[{ required: true, message: "请选择发票类型" }]}
              >
                <Select style={{ width: 180 }} placeholder="请选择发票类型">
                  {allInvoiceType.map((el: any, index: number) => {
                    return (
                      <Option key={index} value={el.key}>
                        {el.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="税务登记号"
                name="unifyCreditCode"
                rules={[
                  {
                    required: true,
                    message: "请输入税务登记号(统一社会信用代码)",
                  },
                ]}
              >
                <Input placeholder="请输入税务登记号(统一社会信用代码)" />
              </Form.Item>
              <Form.Item label="开户行" name="bankDot">
                <Input placeholder="请输入开户行" />
              </Form.Item>
              <Form.Item label="银行账户" name="bankCardNo">
                <Input placeholder="请输入银行账户" />
              </Form.Item>
              <Form.Item label="地址" name="addr">
                <TextArea rows={4} placeholder="请输入企业地址" />
              </Form.Item>
              <Form.Item
                label="企业电话"
                name="tel"
                rules={[
                  {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                    message: "请输入企业电话",
                  },
                ]}
              >
                <Input placeholder="请输入企业电话" />
              </Form.Item>
              <Form.Item
                label="联系电话"
                name="linkPhone"
                rules={[
                  { required: true, message: "请输入联系电话" },
                  {
                    pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                    message: "请输入联系电话",
                  },
                ]}
              >
                <Input maxLength={11} placeholder="请输入联系电话" />
              </Form.Item>

              <Form.Item
                label="电子邮件"
                name="email"
                rules={[
                  {
                    pattern:
                      /^[a-z\d]+(\.[a-z\d]+)*@([\da-z](-[\da-z])?)+(\.{1,2}[a-z]+)+$/,
                    message: "请输入电子邮件",
                  },
                ]}
              >
                <Input placeholder="请输入电子邮件" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button type="primary" className="w-150" htmlType="submit">
                  提交申请
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
