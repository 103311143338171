import Header from "./components/header";
import Footer from "./components/footer";
import DgcountDown from "./components/dg-countDown";
import registerLess from "./register.less";
import { Button, Checkbox, Row, Col, Form, Input, message } from "antd";
import type { FormInstance } from "antd";
import React, { useState, useEffect } from "react";
import { encryptDES } from "./assets/js/util";
import ajax from "./assets/js/ajax";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  let [imgCode, setImgCode] = useState("");
  let refForm = React.createRef<FormInstance>();
  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 重置数据
   * @param
   * @return
   */
  let reset = () => {
    refForm.current?.setFieldsValue({
      enterpriseName: "xxx公司",
      socialCreditCode: "420101198101012470",
      mobile: "17750877003",
      name: "喻欣源",
      password: "a123456",
      captcha: "xxx",
      smsCode: "220919",
    });
  };

  /**
   * @author 伟健 / 张
   * @name 提交注册
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    if (!values.isProtocol) {
      message.warning("请同意协议");
      return;
    }
    values.password = encryptDES(values.password);
    ajax.post({
      url: "/openPlatform/auth/regist",
      data: values,
      self: this,
      success: (res) => {
        console.log(res);
        navigate("/admin");
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信数据校验
   * @param
   * @return
   */
  let checkSmsCodeData = () => {
    let form = refForm.current?.getFieldsValue(["captcha", "mobile"]);
    let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

    if (!reg.test(form.mobile)) {
      message.warning("请输入手机号");
      return false;
    }
    if (!form.captcha) {
      message.warning("请输入图形码");
      return false;
    }
    return true;
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信
   * @param
   * @return
   */
  let handlerSendCode = () => {
    let form = refForm.current?.getFieldsValue(["captcha", "mobile"]);
    return new Promise((resolve, reject) => {
      ajax.post({
        url: "/openPlatform/common/sendSmsCode",
        data: { catchcode: form.captcha, mobile: form.mobile },
        self: this,
        success: (res) => {
          getGetcaptcha();
          resolve(res);
        },
        fail: () => {
          getGetcaptcha();
          reject();
        },
      });
    });
  };

  useEffect(() => {
    getGetcaptcha();
  }, []);

  return (
    <div>
      <div id={registerLess.register}>
        <Header
          backgroundColor="transparent"
          color="#000000"
        ></Header>
        <div className="aui-flex" style={{ height: "100%" }}>
          <div className={`${registerLess.form}`}>
            <p className="aui-font-size-24  aui-text-center aui-padded-t-34 aui-padded-b-34">
              注册
            </p>
            <div>
              <Form
                name=""
                size="large"
                ref={refForm}
                onFinish={handlerSubmint}
              >
                <Form.Item
                  label=""
                  name="enterpriseName"
                  rules={[{ required: true, message: "请输入企业名称" }]}
                >
                  <Input placeholder="* 企业名称" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="socialCreditCode"
                  rules={[
                    { required: true, message: "请输入统一社会信用代码" },
                  ]}
                >
                  <Input placeholder="* 统一社会信用代码" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="name"
                  rules={[{ required: true, message: "请输入姓名" }]}
                >
                  <Input placeholder="* 姓名" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="mobile"
                  rules={[
                    { required: true, message: "请输入手机号" },
                    {
                      pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                      message: "请输入手机号",
                    },
                  ]}
                >
                  <Input
                    maxLength={11}
                    placeholder="* 手机号"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="password"
                  rules={[
                    { required: true, message: "请设置登录密码" },
                    {
                      pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                      message: "6-16位字符，可包含数字、字母，区分大小写",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="* 请设置登录密码"
                    autoComplete="off"
                    maxLength={16}
                  />
                </Form.Item>
                <div className={`${registerLess.top_25} aui-padded-b-15`}>
                  <p className="aui-font-size-12 aui-A1A1A1">
                    6-16位字符，可包含数字、字母，区分大小写
                  </p>
                </div>
                <Form.Item label="" rules={[{ required: true, message: "" }]}>
                  <Row gutter={8}>
                    <Col span={17}>
                      <Form.Item
                        name="captcha"
                        noStyle
                        rules={[
                          { required: true, message: "请输入图片验证码" },
                        ]}
                      >
                        <Input placeholder="图片验证码" />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <img
                        src={imgCode}
                        className={registerLess.imgCode}
                        onClick={() => getGetcaptcha()}
                        alt=""
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="">
                  <Row gutter={8}>
                    <Col span={17}>
                      <Form.Item
                        name="smsCode"
                        noStyle
                        rules={[
                          { required: true, message: "请输入短信验证码" },
                        ]}
                      >
                        <Input placeholder="短信验证码" />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <DgcountDown
                        id="register"
                        before={checkSmsCodeData}
                        cb={handlerSendCode}
                      >
                        发送短信码
                      </DgcountDown>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={registerLess.register_button}
                  >
                    注册
                  </Button>
                </Form.Item>

                <Form.Item
                  name="isProtocol"
                  valuePropName="checked"
                  className={registerLess.top_25}
                >
                  <Checkbox>
                    同意
                    <a href="/protocol" target="_blank" className="aui-0D6ED4">
                      《用户协议》
                    </a>
                  </Checkbox>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Footer isCenter={true}></Footer>
    </div>
  );
};
