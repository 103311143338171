import Header from "./components/header";
import Footer from "./components/footer";

import protocolLess from "./protocol.less";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "./assets/js/ajax";
export default () => {
	let [info, setInfo] = useState({
		realName: "",
	});
	let [html, setHtml] = useState("");

	const navigate = useNavigate();

	/**
	 * @author 伟健 / 张
	 * @name 当前用户信息
	 * @param
	 * @return
	 */
	let getgetUserProtocol = () => {
		ajax.post({
			url: "/openPlatform/auth/getUserProtocol",
			data: {},
			self: this,
			success: (res) => {
				if (res) {
					setHtml(res.content);
				}
			},
			fail: () => {},
		});
	};
	/**
	 * @author 伟健 / 张
	 * @name 当前用户信息
	 * @param
	 * @return
	 */
	let getCurrentInfo = () => {
		ajax.post({
			url: "/openPlatform/user/current",
			data: {},
			self: this,
			success: (res) => {
				//localStorage.setItem("current", JSON.stringify(res));

				
			},
			fail: () => {
			//	navigate("/");
			},
		});
	};
	useEffect(() => {
		//getCurrentInfo();
		getgetUserProtocol();
	}, []);
	return (
		<div>
			<Header info={info} backgroundColor="#0D6ED4" color="#fff" active={true}></Header>

			<div className="w-1200 aui-margin-auto">
				<div
					className={protocolLess.wrap}
					dangerouslySetInnerHTML={{
						__html: html,
					}}
				></div>
			</div>
			<Footer></Footer>
		</div>
	);
};
