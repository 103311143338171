import * as React from "react";
import itemLess from "./css/dg-list-item.less";
interface propsType {
	children?: React.ReactNode;

	column?: number;
	labelWidth?: string;
	label?: String;
	suffix?: String;
}

export default (props: propsType) => {
	let { children, column = 2, labelWidth = "260px", label, suffix = "：" } = props;
	let width = `${100 / column}%`;

	return (
		<div style={{ width: width }} className="aui-flex aui-margin-t-6  aui-margin-b-4">
			<div style={{ flexBasis: labelWidth }} className={`aui-text-right ${itemLess.label}`}>
				{label}
				{label ? suffix : ""}
			</div>
			<div className={itemLess.value}>{children}</div>
		</div>
	);
};
