import * as React from "react";

import { toArray } from "../assets/js/util";

interface propsType {
	children: React.ReactElement | React.ReactElement[];
	width?: string | number;
	labelWidth?: string | number;
	column?: number;
	suffix?: string;
	inline?: boolean;
}

export default (props: propsType) => {
	let { children, width, inline = false, column = 2, labelWidth, suffix = "：" } = props;
	let childNodes = toArray(children);

	let getDecoratedChild = () => {
		return childNodes.map((el, index) => {
			return React.cloneElement(el, { inline, column, labelWidth, suffix, key: index });
		});
	};

	return (
		<div className="aui-flex aui-wrap" style={{ width: width }}>
			{getDecoratedChild()}
		</div>
	);
	// return <div>{children}</div>;
};
