import detailLess from "./css/detail.less";
import Dglist from "../../components/dg-list";

import Dglistitem from "../../components/dg-list-item";


import React, { useState, useEffect, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ajax from "../../assets/js/ajax";

export default memo(() => {
	const navigate = useNavigate();

	const params = useLocation().state as {
		tradeNo: string;
		id: string;
	};

	console.log("params", params);
	const [data, setData] = useState({
		amountStr: "",
		gmtCreate: "",
		tradeNo: "",
		statusDesc: "",
		receiptAccount: "",
		payTypeDesc: "",
		receiptAccountBank: "",
		receiptAccountName: "",
	});
	// 地址： http://jinfu.baohan.com:8080/doc.html#/default/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0%E5%89%8D%E5%8F%B0-%E5%85%85%E5%80%BC%E8%AE%A2%E5%8D%95/uploadCertUsingPOST
	// 接口： /openPlatform/order/uploadCert
	interface orderUploadcert {
		tradeNo: string; // 订单号
		cert?: string; // 凭证
	}
	/**
	 * @author 伟健 / 张
	 * @name 订单详情
	 * @param
	 * @return
	 */
	let getPayInfo = () => {
		ajax.post({
			url: "/openPlatform/order/detail",
			data: params,
			self: this,
			success: (res) => {
				setData(res || {});
			},
			fail: () => {},
		});
	};

	useEffect(() => {
	
			getPayInfo();
		
	}, []);

	return (
		<div className="">
			<div className="aui-padded-b-12 aui-flex aui-row-between">
				<div>
					<span className="aui-font-size-16 aui-192530 aui-padded-r-4">充值详情</span>
				</div>
			</div>
			<div className={`aui-bg-white ${detailLess.wrapa}`}>
				<div className={`aui-padded-l-26 aui-padded-r-26 aui-padded-b-20 aui-padded-t-20`}>
					<Dglist column={2} labelWidth="140px">
						<Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
						<Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
						<Dglistitem label="充值方式">{data.payTypeDesc}</Dglistitem>
						<Dglistitem label="订单状态">{data.statusDesc}</Dglistitem>
						<Dglistitem label="充值金额">
							<span className="aui-F33F41">{data.amountStr}</span>元
						</Dglistitem>
					</Dglist>
				</div>
			</div>
		</div>
	);
});
