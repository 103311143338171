/*
 * @Author:  huangtaizhu@nongdaxia.com.cn
 * @Date: 2023-12-07 15:53:44
 * @LastEditors: 黄太柱 huangtaizhu@nongdaxia.com.cn
 * @LastEditTime: 2024-07-30 14:32:33
 * @FilePath: \finance-open\src\components\footer.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import footerLess from "./css/footer.less";
import wx_code from "@/assets/img/wx_code.png";
import { memo } from "react";

interface PropsType {
  isCenter?: boolean;
}

// wx_code.png
export default memo(({ isCenter = false }: PropsType) => {
  const ContentComp = isCenter ? (
    <div className="w-1200 aui-margin-auto aui-flex aui-col-center aui-row-center">
      <div className="aui-text-left">
        {/* <p className="aui-b1b1b1 aui-font-size-12">
          版权所有 十堰产投信息科技有限公司
        </p> */}
        <p className="aui-padded-t-6 aui-font-size-12">
          <a
            href="https://beian.miit.gov.cn"
            className="aui-b1b1b1"
            target="_blank"
          >
            鲁ICP备2023028601号
          </a>
        </p>
      </div>
    </div>
  ) : (
    <div className="w-1200 aui-margin-auto aui-flex aui-col-center aui-row-between">
      <div className="aui-text-left">
        {/* <p className="aui-b1b1b1 aui-font-size-12">
          版权所有 十堰产投信息科技有限公司
        </p> */}
        <p className="aui-padded-t-6 aui-font-size-12">
          <a
            href="https://beian.miit.gov.cn"
            className="aui-b1b1b1"
            target="_blank"
          >
            鲁ICP备2023028601号
          </a>
        </p>
      </div>
      <div className="aui-margin-l-auto">
        <p className="aui-b1b1b1">客服热线（9:00-18:00）</p>
        <p className="aui-font-size-24 aui-padded-t-10 aui-color-ff">
          400-816-1767
        </p>
      </div>

      <div className="aui-text-center aui-margin-l-20">
        <p>
          <img src={wx_code} alt="" width={68} />
        </p>
        {/* <p className="aui-padded-t-6">客服微信</p> */}
      </div>
    </div>
  );

  return (
    <div id={footerLess.footer} className="aui-padded-b-14 aui-padded-t-20">
      {ContentComp}
    </div>
  );
});
