import CryptoJS from "crypto-js";
type DateReg = {
	"M+": number;
	"d+": number;
	"h+": number;
	"m+": number;
	"s+": number;
	"q+": number;
	S: number;
};
interface Data {
	[key: string]: any;
}
export function toArray(children: React.ReactElement | React.ReactElement[]): React.ReactElement[] {
	var ret: React.ReactElement[] = [];
	foreach<React.ReactElement | React.ReactElement[]>(children, (item) => {
		ret.push(item);
	});
	return ret;
}

function foreach<T>(item: T, cb: (res: any) => void) {
	if (Array.isArray(item)) {
		for (let i of item) {
			cb(i);
		}
	} else {
		cb(item);
	}
}

/* 日期转化 */
export function formatDate(NewDtime: Date): string {
	let datetime = new Date(NewDtime);
	let year = datetime.getFullYear();
	let month: string = (datetime.getMonth() + 1).toString(); //js从0开始取
	let date: string = datetime.getDate().toString();
	let hour: string = datetime.getHours().toString();
	let minutes: string = datetime.getMinutes().toString();
	let second: string = datetime.getSeconds().toString();

	if (parseInt(month) < 10) {
		month = "0" + month;
	}
	if (parseInt(date) < 10) {
		date = "0" + date;
	}
	if (parseInt(hour) < 10) {
		hour = "0" + hour;
	}
	if (parseInt(minutes) < 10) {
		minutes = "0" + minutes;
	}
	if (parseInt(second) < 10) {
		second = "0" + second;
	}
	let time = year + "-" + month + "-" + date + " " + hour + ":" + minutes + ":" + second; //2009-06-12 17:18:05
	return time;
}

function getCookie(name: string) {
	let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	let arr: any = document.cookie.match(reg);
	if (arr) return unescape(arr[2]);
	else return null;
}

function DateStrKey(): string {
	let date: Date = new Date();
	let y: string = date.getFullYear().toString();
	let m: string = (date.getMonth() + 1).toString();
	let d: string = date.getDate().toString();

	if (parseInt(m) < 10) {
		m = "0" + m;
	}
	if (parseInt(d) < 10) {
		d = "0" + d;
	}
	return `${y}-${m}-${d}`;

	// console.log("现在是 " + y + "年" + m + "月" + d + "日 星期 " + day + " " + h + ":" + mi + ":" + s + ":" + ms);
}

// 加密
export const encryptDES = (message: string, desKey?: string): string => {
	const key = desKey || `${getCookie("JSESSIONID")}-${DateStrKey()}`;
	console.log("key", key);
	const keyHex = CryptoJS.enc.Utf8.parse(key);
	if (message) {
		let encrypt = CryptoJS.DES.encrypt(message, keyHex, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7,
		});
		return encrypt.toString();
	} else {
		return "";
	}
};

// 解密
export const decryptDES = (ciphertext: string): string => {
	const key = `${getCookie("JSESSIONID")}-${DateStrKey()}`;
	const keyHex = CryptoJS.enc.Utf8.parse(key);
	if (ciphertext) {
		let decrypted = CryptoJS.DES.decrypt(ciphertext, keyHex, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7,
		});
		return decrypted.toString(CryptoJS.enc.Utf8);
	} else {
		return "";
	}
};

// 检查是否为json格式字符串
export const isJsonStr = (str:string) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

/**
 * @author 伟健 / 张
 * @name 根据key赋值
 * @param
 * @return original
 */
const hasOwnProperty = Object.prototype.hasOwnProperty;
export const setval = (original: Data, _from: Data): Data => {
	for (let key in original) {
		if (hasOwnProperty.call(_from, key)) {
			original[key] = _from[key];
		}
	}
	return original;
};

export let dateFormat = (date: Date, fmt: string): string => {
	if (!date) return "";
	let o: DateReg = {
		"M+": date.getMonth() + 1, // 月份
		"d+": date.getDate(), // 日
		"h+": date.getHours(), // 小时
		"m+": date.getMinutes(), // 分
		"s+": date.getSeconds(), // 秒
		"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
		S: date.getMilliseconds(), // 毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (let k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? (o[k as keyof DateReg] as any) : (("00" + o[k as keyof DateReg]) as any).substr((("" + o[k as keyof DateReg]) as any).length));
		}
	}
	return fmt;
};
/**
 * @author 伟健 / 张
 * @name 合并对象
 * @param
 * @return
 */

export const extend = (to: Data, _from: Data): Data => {
	for (let key in _from) {
		to[key] = _from[key];
	}
	return to;
};
