import Header from "../../components/header";
import Footer from "../../components/footer";

import banner from "@/assets/img/login_bg.png";
import type { FormInstance } from "antd";
import indexLess from "./css/forgotPasw.less";
import { encryptDES } from "../../assets/js/util";
import { Button, Row, Col, Form, Input, message, Carousel } from "antd";
import DgcountDown from "../../components/dg-countDown";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
export default () => {
  let [imgCode, setImgCode] = useState("");
  let refForm = React.createRef<FormInstance>();
  let refFormPasw = React.createRef<FormInstance>();
  let [state, setState] = useState(true);
  const navigate = useNavigate();
  /**
   * @author 伟健 / 张
   * @name 登录
   * @param
   * @return
   */
  let handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/auth/mobileLogin",
      data: values,
      self: this,
      success: (res) => {
        console.log(res);
        if (res) {
          setState(false);
        }
      },
      fail: () => {
        getGetcaptcha();
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信数据校验
   * @param
   * @return
   */
  let checkSmsCodeData = () => {
    let form = refForm.current?.getFieldsValue(["captcha", "mobile"]);
    let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

    if (!reg.test(form.mobile)) {
      message.warning("请输入手机号");
      return false;
    }
    if (!form.captcha) {
      message.warning("请输入图形码");
      return false;
    }
    return true;
  };

  /**
   * @author 伟健 / 张
   * @name 发送短信
   * @param
   * @return
   */
  let handlerSendCode = () => {
    let form = refForm.current?.getFieldsValue(["captcha", "mobile"]);
    return new Promise((resolve, reject) => {
      ajax.post({
        url: "/openPlatform/common/sendSmsCode",
        data: { catchcode: form.captcha, mobile: form.mobile },
        self: this,
        success: (res) => {
          getGetcaptcha();
          resolve(res);
        },
        fail: () => {
          getGetcaptcha();
          reject();
        },
      });
    });
  };
  /**
   * @author 伟健 / 张
   * @name 提交修改密码
   * @param
   * @return
   */
  let handlerSetPasw = (values: any) => {
    Object.keys(values).forEach((key) => {
      values[key] = encryptDES(values[key]);
    });
    ajax.post({
      url: "/openPlatform/user/changePassword",
      data: values,
      self: this,
      success: (res) => {
        console.log(res);
        if (res) {
          navigate("/");
        }
      },
      fail: () => {
        getGetcaptcha();
      },
    });
  };

  useEffect(() => {
    getGetcaptcha();
  }, []);
  return (
    <div>
      <div className={indexLess.bgWrap}>
        <div className={indexLess.bgBox}>
          <img src={banner} alt="" />
        </div>

        <Header
          backgroundColor="transparent"
          color="#000000"
        ></Header>
        <div className={indexLess.banner_wrap}>
          <div className="w-1200 aui-margin-auto aui-rel aui-flex aui-col-center aui-row-center">
            <div className={indexLess.from}>
              <p className="aui-text-center aui-padded-t-48 aui-padded-b-30 aui-font-size-24">
                {state ? "忘记密码" : "设置新密码"}{" "}
              </p>
              {state ? (
                <Form size="large" ref={refForm} onFinish={handlerSubmint}>
                  {/* <Form.Item label="" name="enterpriseName" rules={[{ required: true, message: "请填写账户名" }]}>
									<Input placeholder="* 请填写账户名" autoComplete="off" />
								</Form.Item> */}
                  <Form.Item
                    label=""
                    name="mobile"
                    rules={[
                      { required: true, message: "请输入手机号" },
                      {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
                        message: "请输入手机号",
                      },
                    ]}
                  >
                    <Input
                      maxLength={11}
                      placeholder="* 请输入手机号"
                      autoComplete="off"
                    />
                  </Form.Item>

                  <Form.Item label="" rules={[{ required: true, message: "" }]}>
                    <Row gutter={8}>
                      <Col span={17}>
                        <Form.Item
                          name="captcha"
                          noStyle
                          rules={[
                            { required: true, message: "请输入图片验证码" },
                          ]}
                        >
                          <Input placeholder="图片验证码" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <img
                          src={imgCode}
                          className={indexLess.imgCode}
                          onClick={() => getGetcaptcha()}
                          alt=""
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label="">
                    <Row gutter={8}>
                      <Col span={17}>
                        <Form.Item
                          name="messagecode"
                          noStyle
                          rules={[
                            { required: true, message: "请输入短信验证码" },
                          ]}
                        >
                          <Input placeholder="短信验证码" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <DgcountDown
                          id="register"
                          before={checkSmsCodeData}
                          cb={handlerSendCode}
                        >
                          发送短信码
                        </DgcountDown>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={indexLess.login_button}
                    >
                      下一步
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form size="large" ref={refFormPasw} onFinish={handlerSetPasw}>
                  <Form.Item
                    label=""
                    name="confirmPassword"
                    rules={[
                      { required: true, message: "请设置登录密码" },
                      {
                        pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                        message: "6-16位字符，可包含数字、字母，区分大小写",
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={16}
                      placeholder="请设置新登录密码"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className={`${indexLess.top_25} aui-padded-b-15`}>
                    <p className="aui-font-size-12 aui-A1A1A1">
                      6-16位字符，可包含数字、字母，区分大小写
                    </p>
                  </div>
                  <Form.Item
                    label=""
                    name="newPassword"
                    rules={[
                      { required: true, message: "请设置登录密码" },
                      {
                        pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                        message: "6-16位字符，可包含数字、字母，区分大小写",
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={16}
                      placeholder="请设置新登录密码"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className={`${indexLess.top_25} aui-padded-b-15`}>
                    <p className="aui-font-size-12 aui-A1A1A1">
                      6-16位字符，可包含数字、字母，区分大小写
                    </p>
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={indexLess.login_button}
                    >
                      提交
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer isCenter={true}></Footer>
    </div>
  );
};
