// import { Form, Input } from "antd";
import indexLess from "./css/index.less";
// import { Select, Button, Modal } from "antd";
import { useState, useEffect } from "react";
import ajax from "../../assets/js/ajax";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

// import ofdImg from "@/assets/img/ofd.png";
import { Link, useNavigate } from "react-router-dom";
// import queryImg from "@/assets/img/query.png";
// const { Option } = Select;
export default () => {
  // const navigate = useNavigate();

  let [dataList, setDataList] = useState<any[]>([]);
  let [totalItems, setTotalItems] = useState<number>(0);
  // 地址： http://jinfu.baohan.com:8080/doc.html#/default/%E5%BC%80%E6%94%BE%E5%B9%B3%E5%8F%B0-%E4%BA%A7%E5%93%81%E7%AE%A1%E7%90%86%E6%A8%A1%E5%9D%97/pageUsingPOST_6
  // 接口： /openPlatform/product/page
  interface productPage {
    pageNo: number | null; // 页数
    pageSize: number | null; // 条数
    sortDirection?: string; // 排序方向
    sortField?: string; // 排序字段
  }

  let [formData, setFormData] = useState<productPage>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
    sortDirection: "", // 排序方向
    sortField: "", // 排序字段
  });

  /**
   * @author 伟健 / 张
   * @name 产品列表
   * @param
   * @return
   */
  let getList = (data?: any) => {
    ajax.post({
      url: "/openPlatform/product/page",
      data: Object.assign(formData, data || {}),
      self: this,
      success: (res) => {
        setDataList(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    if (hasPermission("openPlatform:product:page")) {
      getList();
    }
  }, []);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:product:page") ? (
        <div className="">
          <div className="aui-padded-b-12">
            <span className="aui-font-size-16 aui-192530 aui-padded-r-4">
              产品中心
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap} `}>
            {dataList.map((item: any, index: number) => {
              return (
                <Link
                  to="/admin/productDetail"
                  state={{ id: item.id }}
                  className={`${indexLess.bor_bttom} aui-flex aui-col-center aui-link aui-padded-20`}
                >
                  {/* <div className={`${indexLess.icon_box} aui-flex`}>{item.productName.indexOf("查询") > -1 ? <img src={queryImg} alt="" /> : <img src={ofdImg} alt="" />}</div> */}
                  <div className={`${indexLess.icon_box} aui-flex`}>
                    <img src={item.logo} alt="" />
                  </div>
                  <div className="aui-padded-l-20">
                    <p className="aui-font-size-20 aui-333333 w-300 aui-ellipsis-1">
                      {item.productName}
                    </p>
                    <p className="aui-font-size-14 aui-padded-t-10 w-500 aui-666666 aui-ellipsis-1">
                      {item.describe}
                    </p>
                  </div>
                  <div className="aui-row-right">
                    <span>详情 </span> <i className="icon">&#xe646;</i>
                  </div>
                </Link>
              );
            })}

            {/* <a className={`${indexLess.bor_bttom} aui-flex aui-col-center aui-padded-20`}>
						<div className={`${indexLess.icon_box} aui-flex`}>
							<img src={ofdImg} alt="" />
						</div>
						<div className="aui-padded-l-20">
							<p className="aui-font-size-20 aui-333333">OFD文件生成</p>
							<p className="aui-font-size-14 aui-666666">采用OFD格式来存储电子保函文本及相关附件，规范电子保函格式</p>
						</div>
						<div className="aui-row-right">
							<span>详情 </span> <i className="icon">&#xe646;</i>
						</div>
					</a>
					<a className={`${indexLess.bor_bttom} aui-flex aui-col-center aui-padded-20`}>
						<div className={`${indexLess.icon_box} aui-flex`}>
							<img src={ofdImg} alt="" />
						</div>
						<div className="aui-padded-l-20">
							<p className="aui-font-size-20 aui-333333">OFD文件生成</p>
							<p className="aui-font-size-14 aui-666666">采用OFD格式来存储电子保函文本及相关附件，规范电子保函格式</p>
						</div>
						<div className="aui-row-right">
							<span>详情 </span> <i className="icon">&#xe646;</i>
						</div>
					</a>
					<a className={`${indexLess.bor_bttom} aui-flex aui-col-center aui-padded-20`}>
						<div className={`${indexLess.icon_box} aui-flex`}>
							<img src={ofdImg} alt="" />
						</div>
						<div className="aui-padded-l-20">
							<p className="aui-font-size-20 aui-333333">OFD文件生成</p>
							<p className="aui-font-size-14 aui-666666">采用OFD格式来存储电子保函文本及相关附件，规范电子保函格式</p>
						</div>
						<div className="aui-row-right">
							<span>详情 </span> <i className="icon">&#xe646;</i>
						</div>
					</a> */}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};
