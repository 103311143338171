import { Table, Modal, Pagination, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import indexLess from "./css/myProducts.less";
import type { ColumnsType } from "antd/es/table";

import EmptyPage from "../../components/emptyPage";
import { hasPermission } from "../../assets/js/hasPermission";

import React, { useState, useEffect, memo } from "react";
// import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
const { confirm } = Modal;
interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
}

export default memo(() => {
  const columns: ColumnsType<DataType> = [
    {
      title: "Code",
      dataIndex: "productCode",
      key: "productCode",
    },

    {
      title: "产品",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "开通时间",
      dataIndex: "openTime",
      key: "openTime",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "remark",
      align: "center",
      width: "150px",
      render: (text, record: any) => {
        let op1 = (
          <span
            className="aui-padded-l-4 aui-del"
            onClick={() => {
              handelDel(record);
            }}
          >
            删除产品
          </span>
        );

        let oper = [];
        oper.push(op1);
        return oper;
      },
    },
  ];

  let [data, setData] = useState<any>([]);
  let [totalItems, setTotalItems] = useState<number>(0);
  interface orderPage {
    pageNo: number | null; // 页数
    pageSize: number | null; // 条数
  }
  let [formData, setFormData] = useState<orderPage>({
    pageNo: 1, // 页数
    pageSize: 10, // 条数
  });
  let handelDel = (record: any) => {
    confirm({
      title: "删除产品",
      icon: <ExclamationCircleOutlined />,
      content: "确定要删除该产品吗？",
      onOk() {
        ajax.post({
          url: "/openPlatform/product/personalDelete",
          data: { productId: record.id },
          self: this,
          success: (res) => {
            if (res) {
              message.success("删除成功");
              getList();
            }
          },
          fail: () => {},
        });
      },
    });
  };
  /**
   * @author 伟健 / 张
   * @name 列表
   * @param
   * @return
   */
  let getList = () => {
    ajax.post({
      url: "/openPlatform/product/personalPage",
      data: {},
      self: this,
      success: (res) => {
        setData(() => {
          return res.result.map((e: any, i: number) => {
            return { ...e, key: i };
          });
        });
        setTotalItems(res.totalItems);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 分页
   * @param
   * @return
   */

  let handelPageChange = (pageNo: number, pageSize: number) => {
    setFormData({
      pageNo,
      pageSize,
    });
  };
  useEffect(() => {
    if (hasPermission("openPlatform:product:personalPage")) {
      getList();
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:product:personalPage") ? (
        <div className="">
          <div className="aui-padded-b-12">
            <span className="aui-font-size-16 aui-192530 aui-padded-r-4">
              我的产品
            </span>
          </div>
          <div className={`aui-bg-white ${indexLess.wrap} aui-padded-26`}>
            <Table columns={columns} pagination={false} dataSource={data} />
            <div className="aui-text-center aui-padded-t-24">
              <Pagination
                onChange={handelPageChange}
                total={totalItems}
                showTotal={(total) => `总条数 ${total}`}
              ></Pagination>
            </div>
          </div>
          <textarea
            id="copyTxt"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              zIndex: -10,
            }}
          ></textarea>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
