import indexLess from "./css/modifyPasw.less";

import { Button, Form, Input, message } from "antd";

import { hasPermission } from "../../assets/js/hasPermission";
import EmptyPage from "../../components/emptyPage";

import { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { encryptDES } from "../../assets/js/util";

export default memo(() => {
  const navigate = useNavigate();

  let [refForm] = Form.useForm();

  let handlerSubmint = (values: any) => {
    // values.password = encryptDES(values.password);
    console.log(values);
    Object.keys(values).forEach((key) => {
      values[key] = encryptDES(values[key]);
    });
    ajax.post({
      url: "/openPlatform/user/changePasswordNeedOld",
      data: values,
      self: this,
      success: (res) => {
        message.success("修改成功");
        handlerExit();
      },
      fail: () => {},
    });
  };

  let handlerExit = () => {
    ajax.post({
      url: "/openPlatform/auth/logout",
      data: {},
      self: this,
      success: (res) => {
        setTimeout(() => {
          navigate("/");
        }, 1500);
      },
      fail: () => {},
    });
  };

  useEffect(() => {}, []);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hasPermission("openPlatform:user:changePasswordNeedOld") ? (
        <div className="">
          <div className="aui-padded-b-12">
            <span className="aui-font-size-16 aui-192530 aui-padded-r-4">
              修改密码
            </span>
          </div>
          <div
            className={`aui-bg-white ${indexLess.wrap} aui-padded-t-40 aui-padded-b-40`}
          >
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
              form={refForm}
              onFinish={handlerSubmint}
            >
              <Form.Item
                label="旧密码"
                name="oldPassword"
                rules={[{ required: true, message: "请输入旧密码" }]}
              >
                <Input.Password placeholder="请输入旧密码" />
              </Form.Item>
              <Form.Item
                label="新密码"
                name="newPassword"
                rules={[
                  { required: true, message: "请设置登录密码" },
                  {
                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                    message: "6-16位字符，可包含数字、字母，区分大小写",
                  },
                ]}
              >
                <div>
                  <Input.Password placeholder="请输入新密码" />
                  <p className="aui-A1A1A1 aui-font-size-12">
                    6-16位字符，可包含数字、字母，区分大小写
                  </p>
                </div>
              </Form.Item>

              <Form.Item
                label="确认密码"
                name="confirmPassword"
                rules={[
                  { required: true, message: "请设置登录密码" },
                  {
                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                    message: "6-16位字符，可包含数字、字母，区分大小写",
                  },
                ]}
              >
                <div>
                  <Input.Password placeholder="请输入确认密码" />
                  <p className="aui-A1A1A1 aui-font-size-12">
                    6-16位字符，可包含数字、字母，区分大小写
                  </p>
                </div>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4 }}>
                <Button type="primary" htmlType="submit" className="w-280">
                  保 存
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
});
