import { ReactNode, memo, useEffect, useState } from "react";
import Header from "../../components/header";
import ajax from "../../assets/js/ajax";
import { useLocation, useNavigate } from "react-router-dom";
import detailLess from "./css/detail.less";
import layoutLess from "../../layout.less";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import Search from "./components/search";
import { createPortal } from "react-dom";

export default memo(() => {
  const navigate = useNavigate();
  const params = useLocation().state as {
    id?: number | string;
    classifyId?: number | string;
  };

  const [info, setInfo] = useState({
    realName: "",
    balance: 0,
  });
  const [detail, setDetail] = useState({
    content: "",
    title: "",
  });

  const [classifyName, setClassifyName] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("");

  const [tabs, setTabs] = useState<any[]>([]);

  const getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
      },
      fail: () => {
        // navigate("/");
      },
    });
  };

  const goHelpList = () => {
    navigate!("/help");
  };

  const getDetail = (id: string | number, classifyId: string | number) => {
    ajax.post({
      url: "/openPlatform/support/detail",
      data: { id, classifyId },
      self: this,
      success: (res) => {
        setClassifyName(res.vo.classifyName);
        setActiveKey(res.detailVo.id);
        setTabs([...res.vo.details]);
        setDetail(res.detailVo || {});
      },
      fail: () => {},
    });
  };

  const onTabChange = (id: string | number) => {
    setActiveKey(id as string);
    getDetail(id, params.classifyId as number);
  };

  useEffect(() => {
    getCurrentInfo();
    getDetail(params.id as number, params.classifyId as number);
  }, []);

  return (
    <div>
      <Header info={info}></Header>
      <div className={`${detailLess.container} w-1200`}>
        <div className="aui-flex">
          <div className={detailLess.tabs}>
            <div className={`${detailLess.tabTitle} aui-ellipsis`}>
              {classifyName}
            </div>
            <Tabs
              activeKey={activeKey}
              tabPosition="left"
              items={tabs.map((item, i) => {
                return {
                  label: `${item.title}`,
                  key: item.id,
                };
              })}
              onChange={(activeKey) => onTabChange(activeKey)}
            />
          </div>
          <div className={detailLess.rightBox}>
            <div className={detailLess.title}>
              <div className="aui-font-size-24">{detail.title}</div>
              <div>
                <Button type="default" onClick={() => goHelpList()}>
                  返回列表页
                </Button>
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={() => setIsModalOpen(true)}
                >
                  搜索问题
                </Button>
              </div>
            </div>
            <div
              className={detailLess.content}
              dangerouslySetInnerHTML={{
                __html: detail.content,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className={detailLess.footer}>
        <a href="https://beian.miit.gov.cn" target="_blank">
          鲁ICP备2023028601号
        </a>
      </div>
      <div className={layoutLess.contact_wrap}>
        <div className={layoutLess.contact}>
          <i className="icon aui-color-ff aui-font-size-30">&#xe601;</i>
        </div>
        <div className={layoutLess.contact_info}>
          <p className="aui-font-size-16">客服热线：400-816-1767</p>
          <p className="aui-padded-t-6 aui-font-size-16">
            服务时间：工作日9:00-18:00
          </p>
        </div>
      </div>
      {createPortal(
        (
          <Search
            visible={isModalOpen}
            close={() => setIsModalOpen(false)}
            clickItem={(id, classifyId) => {
              setIsModalOpen(false);
              getDetail(id, classifyId);
            }}
          ></Search>
        ) as ReactNode,
        document.getElementById("root") as Element
      )}
    </div>
  );
});
