/*
 * @Author: 黄太柱 huangtaizhu@nongdaxia.com.cn
 * @Date: 2023-12-07 15:53:44
 * @LastEditors: 黄太柱 huangtaizhu@nongdaxia.com.cn
 * @LastEditTime: 2023-12-13 10:54:38
 * @FilePath: \finance-open\src\components\dg-countDown.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { ReactElement } from "react";
import { useState, useEffect } from "react";
import { Button } from "antd";

interface propsType {
	children: ReactElement | ReactElement[] | string;
	id: String;
	cb: () => Promise<any>;
	before: () => boolean;
}

// let [locatConut, setLocatConut] = useState(10);

export default (props: propsType) => {
	let { children, id, before, cb } = props;
	let localId = `countDown_${id}`;
	let _number = 60;

	let [conut, setCount] = useState<number>(_number);
	let [state, setState] = useState<boolean>(false);
	let time: any = null;
	let handlerStart = () => {
		if (before() == false) {
			return;
		}
		if (state === false) {
			cb().then(() => {
				countDown();
			});
		}
	};

	let countDown = () => {
		let localTime = new Date().getTime();
		let cutOffTime = localTime + conut * 1000;
		let now = new Date().getTime();
		if (localStorage.getItem(localId) && now < Number(localStorage.getItem(localId))) {
			cutOffTime = Number(localStorage.getItem(localId));
		}
		localStorage.setItem(localId, `${cutOffTime}`);
		clearInterval(time);
		let fn = () => {
			let now = new Date().getTime();
			if (cutOffTime - now > 0) {
				setCount(parseInt(`${(cutOffTime - now) / 1000}`));
				setState(true);
			} else {
				setCount(_number);
				setState(false);
				localStorage.removeItem(localId);
				clearInterval(time);
			}
		};
		fn();
		
		time = setInterval(fn, 1000);
	};
	useEffect(() => {
	
			let now = new Date().getTime();
			if (localStorage.getItem(localId) && now < Number(localStorage.getItem(localId))) {
				countDown();
			} else {
				localStorage.removeItem(localId);
			}
	
	}, []);
	return <Button style={{ borderColor: "var(--main-color)",color: "var(--main-color)" }} onClick={() => handlerStart()}>{state ? `${conut}/s后发送` : children}</Button>;
	// return <div>{children}</div>;
};
