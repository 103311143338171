import Header from "./components/header";
import Footer from "./components/footer";

import banner from "@/assets/img/index/banner.png";

// import { Carousel } from "antd";
import type { FormInstance } from "antd";
import indexLess from "./login.less";
import { encryptDES } from "./assets/js/util";
import { Button, Row, Col, Form, Input } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ajax from "./assets/js/ajax";
export default () => {
  let [imgCode, setImgCode] = useState("");
  let refForm = React.createRef<FormInstance>();
  // let [isLogin, setIslogin] = useState(false);
  let [info, setInfo] = useState({
    realName: "",
  });
  const navigate = useNavigate();
  /**
   * @author 伟健 / 张
   * @name 登录
   * @param
   * @return
   */
  let handlerLogin = (values: any) => {
    values.password = encryptDES(values.password);
    ajax.post({
      url: "/openPlatform/auth/login",
      data: values,
      self: this,
      success: (res) => {
        navigate("/home");
      },
      fail: () => {
        getGetcaptcha();
      },
    });
  };

  /**
   * @author 伟健 / 张
   * @name 图形码
   * @param
   * @return
   */
  let getGetcaptcha = () => {
    ajax.post({
      url: "/openPlatform/common/getcaptcha",
      data: {},
      self: this,
      success: (res) => {
        if (res) {
          setImgCode(res);
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author 伟健 / 张
   * @name 当前用户信息
   * @param
   * @return
   */
  let getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current?t=index",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
        // setIslogin(false);
        navigate && navigate("/home");
      },
      fail: () => {
        // setIslogin(true);
      },
    });
  };
  useEffect(() => {
    getGetcaptcha();
    getCurrentInfo();
  }, []);
  return (
    <div>
      <div className={indexLess.bannerBg}>
        <div className={indexLess.bgWrap}>
          <img src={banner} alt="" />
        </div>

        <Header
          info={info}
          backgroundColor="transparent"
          color="#000000"
          border="1px solid rgba(0,0,0,.1)"
        ></Header>
        <div className={`${indexLess.banner_wrap} `}>
          <div className="w-1200 aui-margin-auto aui-rel aui-flex aui-col-center aui-row-between">
            <div className={indexLess.formTip}>
              <div className="aui-font-size-32 aui-font-weight-800">
                开放平台
              </div>
              <div className="aui-font-size-24 aui-606266 aui-margin-t-10">
                提供技术服务支持及场景化解决方案
              </div>
            </div>
            <div className={indexLess.loginForm}>
              <p className="aui-padded-t-48 aui-padded-b-38 aui-font-size-24 aui-font-weight-700 aui-text-center">
                登录
              </p>
              <Form
                size="large"
                initialValues={{ remember: true }}
                ref={refForm}
                onFinish={handlerLogin}
                autoComplete="off"
              >
                <Form.Item
                  label=""
                  name="loginName"
                  rules={[{ required: true, message: "请输入账号" }]}
                >
                  <Input placeholder="请输入账号" prefix={<UserOutlined />} />
                </Form.Item>

                <Form.Item
                  label=""
                  name="password"
                  rules={[{ required: true, message: "请输入密码" }]}
                >
                  <Input.Password
                    placeholder="请输入密码"
                    prefix={<UnlockOutlined />}
                  />
                </Form.Item>
                <Form.Item label="">
                  <Row gutter={8}>
                    <Col span={17}>
                      <Form.Item
                        name="catchcode"
                        rules={[{ required: true, message: "请输入图像码" }]}
                      >
                        <Input placeholder="请输入图像码" />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <img
                        src={imgCode}
                        className={indexLess.imgCode}
                        onClick={() => getGetcaptcha()}
                        alt=""
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={indexLess.login_button}
                  >
                    登录
                  </Button>
                </Form.Item>
                <div className={indexLess.top_25}>
                  <p>
                    <span
                      className="aui-link"
                      onClick={() => {
                        navigate("/forgotPasw");
                      }}
                    >
                      忘记密码？
                    </span>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* {isLogin && (
          <div className={`${indexLess.banner_wrap} `}>
            <div className="w-1200 aui-margin-auto aui-rel aui-flex aui-col-center aui-row-between">
              <div className={indexLess.formTip}>
                <div className="aui-font-size-32 aui-font-weight-800">
                  开放平台
                </div>
                <div className="aui-font-size-24 aui-606266 aui-margin-t-10">
                  提供技术服务支持及场景化解决方案
                </div>
              </div>
              <div className={indexLess.loginForm}>
                <p className="aui-padded-t-48 aui-padded-b-38 aui-font-size-24 aui-font-weight-700 aui-text-center">
                  登录
                </p>
                <Form
                  size="large"
                  initialValues={{ remember: true }}
                  ref={refForm}
                  onFinish={handlerLogin}
                  autoComplete="off"
                >
                  <Form.Item
                    label=""
                    name="loginName"
                    rules={[{ required: true, message: "请输入账号" }]}
                  >
                    <Input placeholder="请输入账号" prefix={<UserOutlined />} />
                  </Form.Item>

                  <Form.Item
                    label=""
                    name="password"
                    rules={[{ required: true, message: "请输入密码" }]}
                  >
                    <Input.Password
                      placeholder="请输入密码"
                      prefix={<UnlockOutlined />}
                    />
                  </Form.Item>
                  <Form.Item label="">
                    <Row gutter={8}>
                      <Col span={17}>
                        <Form.Item
                          name="catchcode"
                          rules={[{ required: true, message: "请输入图像码" }]}
                        >
                          <Input placeholder="请输入图像码" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <img
                          src={imgCode}
                          className={indexLess.imgCode}
                          onClick={() => getGetcaptcha()}
                          alt=""
                        />
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className={indexLess.login_button}
                    >
                      登录
                    </Button>
                  </Form.Item>
                  <div className={indexLess.top_25}>
                    <p>
                      <span
                        className="aui-link"
                        onClick={() => {
                          navigate("/forgotPasw");
                        }}
                      >
                        忘记密码？
                      </span>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )} */}
      </div>
      <div className={indexLess.applyBg}>
        <div className="w-1200 aui-margin-auto">
          <div className="aui-text-left aui-padded-t-48 aui-padded-b-40">
            <p className="aui-font-size-32 aui-font-weight-900 aui-color-33">
              接入申请
            </p>
          </div>
          <div className="aui-flex w-full aui-padded-b-40 aui-margin-b-30">
            <div className={indexLess.applyItem}>
              <a href="">申请接入</a>
            </div>
            <div className={indexLess.applyItem}>
              <a href="">对接开发</a>
            </div>
            <div className={indexLess.applyItem}>
              <a href="">联调测试</a>
            </div>
            <div className={indexLess.applyItem}>
              <a href="">上线</a>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
      {/* 接入咨询  */}
      <div className={indexLess.contact_wrap}>
        <div className={indexLess.contact_info}>
          <p className="aui-font-size-14">客服热线：(9:00-18:00)</p>
          <p className="aui-padded-t-6 aui-font-size-16 aui-0D6ED4">
            400-816-1767
          </p>
        </div>
        <div className={indexLess.contact}>
          <p className="aui-text-center">
            <i className="icon aui-color-ff aui-font-size-22">&#xe642;</i>
          </p>
          <div className={indexLess.contact_txt}>接入咨询</div>
        </div>
      </div>
    </div>
  );
};
