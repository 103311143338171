// import * as React from "react";

// import { toArray } from "../assets/js/util";
import emptyPage_less from "./css/emptyPage.less"
import emptyBg from "../assets/img/empty-bg.png";

export default () => {

	return (
		<div className={emptyPage_less.emptyPage}>
      <img style={{ width: '200px',height: '200px'}} src={emptyBg} alt="" />
      <p>无权限，请联系管理员</p>
		</div>
	);
	// return <div>{children}</div>;
};