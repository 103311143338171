import indexLess from "./css/productDetail.less";
import { Select, Button, Modal, message } from "antd";
import { useState, useEffect } from "react";
import ajax from "../../assets/js/ajax";
import { useNavigate, useLocation } from "react-router-dom";
import ofdImg from "@/assets/img/ofd.png";
import queryImg from "@/assets/img/query.png";
const { Option } = Select;
export default () => {
	const navigate = useNavigate();

	let [invoiceVisible, setInvoiceVisible] = useState(false);
	const [detail, setDetail] = useState({
		describe: "",
		detail: "",
		productName: "",
		logo: "",
		productCode: "",
		status: false,
	});
	const params = useLocation().state as {
		id: string;
	};
	/**
	 * @author 伟健 / 张
	 * @name 产品详情
	 * @param
	 * @return
	 */
	let getDetail = () => {
		if (!params.id) {
			return;
		}
		ajax.post({
			url: "/openPlatform/product/detail",
			data: { id: params.id },
			self: this,
			success: (res) => {
				setDetail(res);
			},
			fail: () => {},
		});
	};
	/**
	 * @author 伟健 / 张
	 * @name 开通产品
	 * @param
	 * @return
	 */
	let handlerOpen = () => {
		if (!params.id) {
			return;
		}
		ajax.post({
			url: "/openPlatform/product/personalOpen",
			data: { productId: params.id },
			self: this,
			success: (res) => {
				console.log(res);
				if (res) {
					setInvoiceVisible(false);
					getDetail();
					message.success("开通成功");
				}
			},
			fail: () => {},
		});
	};
	useEffect(() => {
		getDetail();
	}, []);
	return (
		<div className="">
			<div className="aui-padded-b-12">
				<span className="aui-font-size-16 aui-192530 aui-padded-r-4">产品详情</span>
			</div>
			<div className={`aui-bg-white ${indexLess.wrap} `}>
				<div className={`${indexLess.bor_bttom}`}>
					<div className={` aui-flex aui-col-center aui-padded-20`}>
						<div className={`${indexLess.icon_box} aui-flex`}>
							<img src={detail.logo} alt="" />
						</div>
						<div className="aui-padded-l-20">
							<p className="aui-font-size-20 aui-333333">{detail.productName}</p>
							<p className="aui-font-size-14 aui-padded-t-10 aui-666666">Code：{detail.productCode}</p>
						</div>
						<div className="aui-row-right">
							{detail.status ? (
								<Button type="primary">已开通</Button>
							) : (
								<Button type="primary" onClick={() => setInvoiceVisible(true)}>
									申请开通
								</Button>
							)}
						</div>
					</div>
					<p className="aui-666666 aui-font-size-14 aui-padded-l-20 aui-padded-b-12">描述：{detail.describe}</p>
				</div>
				<div className="aui-padded-20">
					<p>
						<span className={`aui-font-size-18 aui-192530 ${indexLess.title}`}>说明</span>
					</p>
					<div
						className="aui-padded-12"
						dangerouslySetInnerHTML={{
							__html: detail.detail,
						}}
					></div>
				</div>
			</div>
			{/* open={isModalOpen} onOk={handleOk} onCancel={handleCancel} */}
			{/* <Modal title="申请发票" > */}
			<Modal forceRender={true} maskClosable={true} onCancel={() => setInvoiceVisible(false)} open={invoiceVisible} footer={false} centered width={400}>
				<div className="aui-text-center ">
					<p className="aui-font-size-20 aui-color-main aui-padded-t-16">开通产品</p>
					<p className="aui-padded-t-8 aui-font-size-14 aui-999CA1">确定开通此产品？</p>
					<div className="aui-padded-t-30">
						<Button className="w-200" type="primary" onClick={() => handlerOpen()}>
							确定
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
