// import headLess from "./css/header.module.less";
import { Link } from "react-router-dom";
import headLess from "./css/header.less";
import logo from "@/assets/img/logo_txt.png";
import logo_ from "@/assets/img/logo_txt_.png";
import messageIcon from "@/assets/img/message_icon.png";
import { memo, useEffect, useRef, useState } from "react";
import {
  Menu,
  Dropdown,
  Modal,
  Checkbox,
  Col,
  Row,
  message,
  notification,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import type { NavigateFunction, Location } from "react-router-dom";
import WSocket from "../assets/js/websocket";
import ajax from "../assets/js/ajax";
import MessageMP3 from "../assets/message.mp3";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
interface propsType {
  active?: boolean;
  backgroundColor?: string;
  color?: string;
  info?: any;
  border?: string;
}
let navigate: NavigateFunction | null = null;
let location: Location | null = null;
let webSocket: WSocket | null = null;

let handlerExit = () => {
  ajax.post({
    url: "/openPlatform/auth/logout",
    data: {},
    self: this,
    success: (res) => {
      webSocket && webSocket.destroy();
      webSocket = null;
      window.localStorage.clear();
      let pathname = location && location.pathname;
      if (pathname === "/") {
        window.location.reload();
      } else {
        navigate && navigate("/");
      }
    },
    fail: () => {},
  });
};

export default memo(
  ({
    active = false,
    backgroundColor = "",
    color = "",
    info = {},
    border = "",
  }: propsType) => {
    navigate = useNavigate();

    location = useLocation();
    let pathname = location && location.pathname;

    const audioRef = useRef<HTMLAudioElement | null>(null);

    const [showNotification, setShowNotification] = useState<boolean>(false);

    const [
      switchOnEngineeringContractOrder,
      setSwitchOnEngineeringContractOrder,
    ] = useState<boolean>(false);
    const [switchOnFarmerSalaryOrder, setSwitchOnFarmerSalaryOrder] =
      useState<boolean>(false);
    const [
      switchOnEngineeringQualityOrder,
      setSwitchOnEngineeringQualityOrder,
    ] = useState<boolean>(false);
    const [switchOnPurchaseContractOrder, setSwitchOnPurchaseContractOrder] =
      useState<boolean>(false);
    const [switchOnCourtLawsuitOrder, setSwitchOnCourtLawsuitOrder] =
      useState<boolean>(false);

    const [defaultValueGrop, setDefaultValueGrop] = useState<string[]>([]);

    const onChange = (checkedValues: CheckboxValueType[]) => {
      setSwitchOnEngineeringContractOrder(checkedValues.includes("A"));
      setSwitchOnEngineeringQualityOrder(checkedValues.includes("B"));
      setSwitchOnFarmerSalaryOrder(checkedValues.includes("C"));
      setSwitchOnPurchaseContractOrder(checkedValues.includes("D"));
      setSwitchOnCourtLawsuitOrder(checkedValues.includes("E"));
    };

    const onOkNotification = (e: any) => {
      ajax.post({
        url: "/openPlatform/userMessageSetting/update",
        data: {
          switchOnEngineeringContractOrder,
          switchOnFarmerSalaryOrder,
          switchOnEngineeringQualityOrder,
          switchOnPurchaseContractOrder,
          switchOnCourtLawsuitOrder,
        },
        self: this,
        success: (res) => {
          message.success("操作成功");
          getSettings();
          setShowNotification(false);
        },
        fail: () => {},
      });
    };

    const getSettings = () => {
      ajax.post({
        url: "/openPlatform/userMessageSetting/current",
        self: this,
        success: (res) => {
          setSwitchOnEngineeringContractOrder(
            res.switchOnEngineeringContractOrder
          );
          setSwitchOnEngineeringQualityOrder(
            res.switchOnEngineeringQualityOrder
          );
          setSwitchOnFarmerSalaryOrder(res.switchOnFarmerSalaryOrder);
          setSwitchOnPurchaseContractOrder(res.switchOnPurchaseContractOrder);
          setSwitchOnCourtLawsuitOrder(res.switchOnCourtLawsuitOrder);
          setDefaultValueGrop((data: any) => {
            if (res.switchOnEngineeringContractOrder) {
              data.push("A");
            }
            if (res.switchOnEngineeringQualityOrder) {
              data.push("B");
            }
            if (res.switchOnFarmerSalaryOrder) {
              data.push("C");
            }
            if (res.switchOnPurchaseContractOrder) {
              data.push("D");
            }
            if (res.switchOnCourtLawsuitOrder) {
              data.push("E");
            }
            return data;
          });
        },
        fail: () => {},
      });
    };

    const menu = (
      <Menu
        items={[
          {
            key: "0",
            label: (
              <span onClick={() => setShowNotification(true)}>通知管理</span>
            ),
          },
          {
            key: "1",
            label: (
              <span
                onClick={() => {
                  navigate && navigate("/admin/modifyPasw");
                }}
              >
                修改密码
              </span>
            ),
          },
          {
            key: "2",
            label: <span onClick={() => handlerExit()}>退出登录</span>,
          },
        ]}
      />
    );

    type Detail = {
      status?: string;
      tradeNo?: string;
      type?: string;
    };

    const copyTradeNo = (txt: string | undefined | null) => {
      if (!txt) return;
      if (navigator.clipboard && window.isSecureContext) {
        message.success("复制成功");
        return navigator.clipboard.writeText(txt);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = txt;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        message.success("复制成功");
        return new Promise<void>((res, rej) => {
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    };

    const key = "updatable";

    const NotifiContent = (props: Detail) => {
      const { status, tradeNo, type } = props;
      return (
        <div className={headLess.notification}>
          <p className={headLess.notifiTitle}>你有一条新的通知，请及时处理!</p>
          <p>【类型】：{type}</p>
          <p>【状态】：{status}</p>
          <p onDoubleClick={() => copyTradeNo(tradeNo)}>【编号】：{tradeNo}</p>
        </div>
      );
    };

    const openNotification = (detail: Detail) => {
      notification.open({
        key,
        duration: null,
        message: <div style={{ color: "#FF0000", fontSize: "16px" }}>通知</div>,
        description: (
          <NotifiContent
            type={detail.type}
            status={detail.status}
            tradeNo={detail.tradeNo}
          ></NotifiContent>
        ),
        icon: (
          <div className={headLess.notifiIcon}>
            <img src={messageIcon} alt="" />
          </div>
        ),
        style: {
          width: 420,
        },
      });
      const audio = document.getElementById("messageMp3") as HTMLAudioElement;
      audio.muted = false;
      audio.play();
    };

    const handleClickMP3 = () => {
      const audio = document.getElementById("messageMp3") as HTMLAudioElement;
      audio.muted = true;
      audio.play();
    };

    const openAudio = () => {
      document.addEventListener("click", handleClickMP3);
    };

    const handleConnet = () => {
      const current = JSON.parse(
        (localStorage.getItem("current") as string) || "{}"
      );
      if (webSocket || !current?.user?.id) {
        return;
      }
      const url =
        process.env.REACT_APP_WEBSOCKET_URL +
        `?accessToken=${current.user.id}@1`; //ws地址
      webSocket = new WSocket({
        url,
        reconnectTime: 5000,
        messageCb: (e: any) => {
          if (e != "pong") {
            const data = JSON.parse(e);
            const content = JSON.parse(data.content);
            openNotification(content || {});
          }
        },
      });
    };

    useEffect(() => {
      getSettings();
      openAudio();
      return () => {
        document.removeEventListener("click", handleClickMP3);
      };
    }, [showNotification]);

    useEffect(() => {
      handleConnet();
    }, [info.realName]);

    return (
      <div>
        <div
          style={{
            backgroundColor: backgroundColor,
            color: color,
            border: border,
          }}
          className={`${active && headLess.active} ${headLess.header}`}
        >
          <div
            className={`aui-flex w-1200 aui-margin-auto aui-row-between aui-col-center ${headLess.headerH}`}
          >
            <div>
              <Link to="/">
                <img
                  src={active ? logo : logo_}
                  className={headLess.logo}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="aui-flex">
              <Link
                to="/help"
                style={{ color: color }}
                className="aui-191a24 aui-font-size-13 aui-padded-r-30"
              >
                帮助支持
              </Link>

              {info.realName ? (
                <Dropdown overlay={menu} placement="bottomLeft">
                  <p
                    onClick={() => {
                      navigate && navigate("/home");
                    }}
                  >
                    <span className="aui-pointer">
                      <i className="icon" style={{ color: color }}>
                        &#xe63f;
                      </i>
                      {info.realName}
                    </span>
                  </p>
                </Dropdown>
              ) : pathname === "/register" ? (
                <Link
                  style={{ color: color }}
                  className="aui-191a24 aui-font-size-13 aui-padded-l-30"
                  to={{ pathname: "/login" }}
                >
                  立即登录
                </Link>
              ) : (
                <Link
                  style={{ color: color }}
                  className="aui-191a24 aui-font-size-13 aui-padded-l-30"
                  to={{ pathname: "/register" }}
                >
                  免费注册
                </Link>
              )}
            </div>
          </div>
        </div>
        <audio
          id="messageMp3"
          ref={audioRef}
          src={MessageMP3}
          style={{ display: "none" }}
        ></audio>
        <Modal
          width={500}
          centered={true}
          title="通知管理"
          forceRender={true}
          maskClosable={true}
          onCancel={() => setShowNotification(false)}
          onOk={onOkNotification}
          open={showNotification}
        >
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChange}
            defaultValue={defaultValueGrop}
          >
            <Row gutter={[20, 20]}>
              <Col span={12}>
                <Checkbox value="A">工程履约保函订单</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="B">农民工支付保函订单</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="C">工程质量保函订单</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="D">政采履约保函订单</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="E">法院诉讼保函订单</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Modal>
      </div>
    );
  }
);
